import { isAfter, isBefore, isEqual, parse, parseISO } from 'date-fns'
import type { RuleParamSchema } from 'vee-validate/dist/types/types'

const validate = (value: string, { min, max, inclusivity = '()', format }: Record<string, any>) => {

    const dateVal =
        format ?
            parse(value, format, new Date()) :
            parseISO(value)

    const minDate =
        typeof min === 'string' ?
            (format ?
                parse(min, format, new Date()) :
                parseISO(min))
            : min

    const maxDate =
        typeof max === 'string' ?
            (format ?
                parse(max, format, new Date()) :
                parseISO(max)) :
            max

    if (!dateVal || !minDate || !maxDate)
        return false

    return (
        (
            isAfter(dateVal, minDate) ||
            inclusivity[0] === '[' && isEqual(dateVal, minDate)
        ) &&

        (
            isBefore(dateVal, maxDate) ||
            inclusivity[1] === ']' && isEqual(dateVal, maxDate)
        )
    )
}

const params: RuleParamSchema[] = [
    {
        name: 'min'
    },
    {
        name: 'max'
    },
    {
        name: 'inclusivity'
    },
    {
        name: 'format'
    }
]

export { validate, params }

export default {
    validate,
    params
}