import { BContainer } from 'bootstrap-vue'
import { defineComponent } from 'vue'

export default defineComponent({
    functional: true,
    render() {
        return (
            <footer class="footer mt-auto">
                <BContainer fluid class="bg-dark">
                    <div class="text-white text-center small py-3">
                        <p>&copy; {new Date().getFullYear()} Vitality Corporate Services Limited trading as VitalityHealth and VitalityLife. All rights reserved.</p>
                    </div>
                </BContainer>
            </footer>
        )
    }
})