import { apiUrls } from '~/data/defaults.json'
import { asyncDebounce, getAsync } from '~/utils'

interface IValidatePostcode {
    isValid: boolean
    formattedValue: string
}

const isValid = (value: string) => {

    const re = /^(([gG][iI][rR] {0,}0[aA]{2})|((([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y]?[0-9][0-9]?)|(([a-pr-uwyzA-PR-UWYZ][0-9][a-hjkstuwA-HJKSTUW])|([a-pr-uwyzA-PR-UWYZ][a-hk-yA-HK-Y][0-9][abehmnprv-yABEHMNPRV-Y]))) {0,}[0-9][abd-hjlnp-uw-zABD-HJLNP-UW-Z]{2}))$/

    return re.test(value)
}

const validate = asyncDebounce(async (value: string) => {

    let valid = isValid(value)

    if (valid) {
        const response =
            await getAsync<IValidatePostcode>(
                `${apiUrls.services}/Postcode/${value}/Validate`,
                undefined,
                () => {

                }
            )

        if (response)
            valid = response.data.isValid
    }

    return valid
}, 100)

export { validate, isValid }

export default {
    validate,
    isValid
}