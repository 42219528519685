import { apiUrls, config } from '~/data/defaults.json'
import type { BenefitTypeType, BusinessQuoteStatusType, EntityTypeType, IAgency, IApiResponse, IBaseEntity, IBaseNumber, IBenefit, IBenefitCategory, IBundle, IBusinessQuote, IContentPage, IEmployeeGroup, IExcessAmount, IExcessType, IHospitalList, IImage, IInsurer, IInsurerHospitalList, IInsurerModule, IInsurerScheme, IMedicalConditionGroup, IModule, IModuleGroup, IName, IProfile, IQuestionnaire, ISettings, ITemplate, IUnderwritingAction, IUnderwritingQuestion, IUnderwritingQuestionAnswer, IUnderwritingType, ImageTypeType, Nullable, PaymentPlanType, PremiumType, TemplateTypeType } from '@/models'
import { IOption } from '@/models'
import router from '~/router'
import { useStore } from '~/store'
import type { AxiosError, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { differenceInYears, format, isValid, parse, parseISO } from 'date-fns'
import { debounce, startCase, truncate } from 'lodash-es'
import MarkdownIt from 'markdown-it'
import MarkdownItAbbr from 'markdown-it-abbr'
import MarkdownItSub from 'markdown-it-sub'
import MarkdownItSup from 'markdown-it-sup'
import type { Route } from 'vue-router'

export const noBreakClass = 'no-break'
export const breakAfterClass = 'break-after'

export const primaryTableHeader = 'bg-primary text-white'
export const defaultCardClass = 'border-0 shadow-sm'
export const noBorderListItemClass = 'border-left-0 border-right-0'
export const justifyBetweenClass = 'd-flex justify-content-between'
export const justifyBetweenCenterClass = `${justifyBetweenClass} align-items-center`
export const alignRightLgClass = 'text-lg-right'
export const vitalityTableClass = 'vitality-table'

export const nameof = <T extends {}>(name: Extract<keyof T, string>) => name

export const instanceOf = <T>(property: string, object: any): object is T =>
    property in object

export const copyToObject = <T extends Nullable<{}>>(value: T) =>
    JSON.parse(JSON.stringify(value)) as T

export const calculateAge = (startDate: Date, value: string) =>
    differenceInYears(startDate, parseISO(value))

export const removeItem = <T extends IBaseNumber>(array: T[], item: T) => {
    const index = array.findIndex(i => i === item || i.id === item.id)

    if (index >= 0)
        removeItemAtIndex(array, index)
}

export const removeItemAtIndex = <T extends {}>(array: T[], index: number) => {
    array.splice(index, 1)
}

export const updateArray = <T extends {}>(array: T[], newArray: T[]) => {
    array.splice(0)
    array.push(...newArray)
}

export const getAgencyName = (agency?: Nullable<IAgency>, truncateName = true) => {

    if (!agency)
        return null

    const cleanedName = agency.name.replace(`-${agency.id}`, '')

    return `${truncateName ? truncate(cleanedName, { length: 20 }) : cleanedName} (${agency.id})`
}

export const isIos = () =>
    /iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

export function asyncDebounce<
    F extends (...args: any[]) => Promise<any>
>(func: F, wait?: number) {
    const resolveSet = new Set<(p: any) => void>()
    const rejectSet = new Set<(p: any) => void>()

    const debounced = debounce((args: Parameters<F>) => {
        func(...args)
            .then((...res) => {
                resolveSet.forEach((resolve) => resolve(...res))
                resolveSet.clear()
            })
            .catch((...res) => {
                rejectSet.forEach((reject) => reject(...res))
                rejectSet.clear()
            })
    }, wait)

    return (...args: Parameters<F>): ReturnType<F> => new Promise((resolve, reject) => {
        resolveSet.add(resolve)
        rejectSet.add(reject)
        debounced(args)
    }) as ReturnType<F>
}

export const setSettings = async () => {
    const response = await getAsync<ISettings>(apiUrls.settings)

    if (response)
        useStore().mutateSettings(response.data)
}

export const setProfile = async () => {
    const response = await getAsync<IProfile>(apiUrls.profile)

    if (response)
        useStore().mutateProfile(response.data)
}

export const addScripts = () => {
    const scriptUrls = useStore().settings?.scripts?.split(', ')

    scriptUrls?.forEach(url => {
        const script = document.createElement('script')

        script.async = true
        script.setAttribute('src', url)
        document.body.appendChild(script)
    })
}

export const downloadFile = async (
    downloadUrl: string,
    fileName?: string,
    onRequestStart?: () => void,
    onRequestEnd?: () => void,
    onDownloadComplete?: () => void) => {

    if (!isIos()) {
        onRequestStart?.()

        const response = await getAsync<Blob>(downloadUrl, {
            responseType: 'blob'
        })

        onRequestEnd?.()

        const data = response?.data
        if (!data)
            return

        // BLOB NAVIGATOR
        const url = URL.createObjectURL(data)
        const link = document.createElement('a')

        link.target = '_blank'
        link.href = url

        const contentDisposition = response?.headers['content-disposition']

        if (contentDisposition) {
            const matches = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)

            if (matches != null && matches[1])
                link.download = matches[1].replace(/['"]/g, '')
        }

        if (fileName)
            link.download = fileName

        link.click()

        URL.revokeObjectURL(url)
        link.remove()
    }

    onDownloadComplete?.()
}

export const toFactor = (value: number) =>
    (100 + value) / 100

export const toPercentage = (value: number) =>
    (value * 100) - 100

export const formatEnum = (value?: Nullable<string>) => {
    const format = /^[a-z]+$/i

    if (!value)
        return null

    return format.test(value) ? startCase(value) : value
}

export const mapToEnumOptions = (value: (number | string | boolean)[]) =>
    value.map(v => ({ 'text': v === true || v === false ? formatBool(v) : formatEnum(v.toString()) ?? '', 'value': v }) as IOption<number | string | boolean>)

export const formatBool = (value?: Nullable<boolean>) => {
    if (value === null || value === undefined)
        return null

    return value ? 'Yes' : 'No'
}

export const formatPhoneNumber = (value?: Nullable<string>) => {
    if (value === null || value === undefined)
        return null

    return value.replaceAll('-0', '(0)').replaceAll('-', '')
}

export const formatAmount = (value?: Nullable<number>, decimals = 2) =>
    value?.toLocaleString('en-GB', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }) ?? "-"

export const formatPremium = (value?: Nullable<number>) =>
    value?.toLocaleString('en-GB', { style: 'currency', currency: 'GBP' }) ?? "-"

export const formatInsurerPremium = (value?: Nullable<number>, paymentFrequency?: Nullable<PaymentPlanType> | Nullable<PremiumType>) => {
    let v = formatPremium(value)

    if (v && paymentFrequency) {
        const unit = paymentFrequency === 'Monthly' ? 'month' : paymentFrequency === 'Quarterly' ? 'quarter' : 'year'

        v += ` per ${unit}`
    }

    return v
}

export const formatPercentage = (value?: Nullable<number>) =>
    (value !== undefined && value !== null ? value / 100 : value)?.toLocaleString('en-GB', { style: 'percent', minimumFractionDigits: 2 })

export const formatDiscount = (value?: Nullable<number>) =>
    value !== undefined && value !== null ? `${formatPercentage(value)} (${toFactor(value)})` : null

export const formatDate = (value?: Nullable<Date | string>, dateFormat?: string) => {
    if (!value)
        return null

    const date =
        typeof value === 'string' ?
            parseISO(value) :
            value

    return isValid(date) ? format(date, dateFormat ?? config.dateFormat) : null
}

export const mapToNames = <T extends IBaseEntity>(entities: T[]) =>
    entities.map(x => x.name)

export const mapToIOptions = <T extends IBaseEntity>(entities: T[]) =>
    entities.map(x => ({ text: x.name, value: x.id }) as IOption<number>)

export const toCommaSeparatedList = <T extends IName>(values?: T[]) =>
    values?.map(x => x.name).join(', ') ?? '-'

export const ukDateToServerString = (value: string) => {
    const d = value.split('/')
    return `${d[2]}-${d[1]}-${d[0]}T00:00:00`
}

export const getModuleLabel = (mg: IModuleGroup, modules: IModule[]) => {
    const selectedModule = modules?.find(m => m.moduleGroupId === mg.id)

    return mg.isIncluded && selectedModule ?
        'Included' :
        (
            mg.includedWithModuleId && modules?.some(m => m.id === mg.includedWithModuleId) ?
                `Included with ${modules?.find(m => m.id === mg.includedWithModuleId)?.name}` :
                selectedModule?.name ??
                (
                    mg.requiredModuleGroupId && modules?.some(m => m.moduleGroupId === mg.requiredModuleGroupId) ?
                        `Within ${modules?.find(m => m.moduleGroupId === mg.requiredModuleGroupId)?.name} limit` :
                        'Not included'
                )
        )
}

export const getStatusLinkedExcessLabel = (g: IEmployeeGroup) =>
    g.hasStatusLinkedExcess === null ? 'Not available' : g.hasStatusLinkedExcess === true ? 'Selected' : 'Not selected'

export const showQuestionnaireStatus = (q: IQuestionnaire) =>
    q.status === 'Pending' || q.status === 'RequiresChanges' || q.status === 'RequiresValidation'

export const isRoute = (route: Route, prefix: string) => {
    const path = route.path.toLowerCase()

    return path.startsWith(prefix) || path.indexOf(`${prefix}/`) > -1
}

export const toTitleCase = (val: string) =>
    `${val.charAt(0).toUpperCase()}${val.slice(1)}`

export const arrayFromNumber = (val: number) =>
    [...Array(val).keys()]

export const parseDate = (value: string | Date, dateFormat: string) => {
    if (typeof value !== 'string')
        return isValid(value) ? value : null

    const parsed = parse(value, dateFormat, new Date())

    // if date is not valid or the formatted output after parsing does not match
    // the string value passed in (avoids overflows)
    if (!isValid(parsed) || format(parsed, dateFormat) !== value)
        return null

    return parsed
}

export const postcodeFormatter = (val: Nullable<string>) => {
    if (val) {
        val = val.toUpperCase()

        const parts = val.match(/^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/)

        if (parts) {
            parts.shift()
            val = parts.join(' ')
        }
    }

    return val
}

export const phoneNumberFormatter = (val: Nullable<string>) => {
    if (val) {
        val = val.toUpperCase()

        const parts = val.match(/\D*(\d{3})\D*(\d{4})\D*(\d{4})\D*/)

        if (parts) {
            parts.shift()
            val = parts.join(' ')
        }
    }

    return val
}

export const dateFormatter = (val: Nullable<string>) => formatDate(val)

export const nameFormatter = (val: Nullable<string>) =>
    val ? toTitleCase(val) : undefined

export const quotationNumberFormatter = (val: Nullable<string>) =>
    val?.replace(/(\d{8})(\d{3})/, '$1/$2')

export const sortCodeFormatter = (val: Nullable<string>) => {
    if (val)
        val = val.replace(/(\d{2})(\d{2})(\d{2})/, '$1-$2-$3')

    return val
}

export const accountNumberFormatter = (val: Nullable<string>) =>
    val?.replace(/(\d{8})/, '$1')

export const optionalValidation = {
    required: false
}

export const requiredValidation = {
    required: true
}

export const requiredTrueValidation = {
    required: {
        allowFalse: false
    }
}

export const quotationNumberValidation = {
    ...requiredValidation,
    //max: 12,
    regex: /^\d{8}\/\d{3}$/
}

export const sortCodeValidation = {
    ...requiredValidation,
    //max: 8,
    'sort_code': true
}

export const accountNumberValidation = {
    ...requiredValidation,
    //max: 8,
    'account_number': true
}

export const emailValidation = {
    ...requiredValidation,
    max: 320,
    email: true
}

export const urlValidation = {
    ...requiredValidation,
    max: 320,
    url: true
}

export const phoneValidation = {
    ...requiredValidation,
    //max: 16,
    'phone_number': true
}

export const nameValidation = {
    ...requiredValidation,
    //min: 2,
    max: 60,
    name: true
}

export const minValidation = (value: number) => ({
    ...requiredValidation,
    'min_value': value
})

export const amountValidation = minValidation(1)

export const isNaN = (value: unknown) => {
    return value !== value
}

export const isNullOrUndefined = (value: unknown) => {
    return value === null || value === undefined
}

const md =
    new MarkdownIt({ html: true, breaks: true, linkify: true, typographer: true })
        .use(MarkdownItSub)
        .use(MarkdownItSup)
        .use(MarkdownItAbbr)

// Remember old renderer, if overridden, or proxy to default renderer
const defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options)
}

md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
    tokens[idx].attrPush(['target', '_blank'])

    // pass token to default renderer.
    return defaultRender(tokens, idx, options, env, self)
}

export const getHtmlContent = (content?: Nullable<string>) =>
    md.render(content ?? '')

export const httpClient = axios.create({
    baseURL: '/',

    withCredentials: true,

    headers: {
        'Cache-Control': 'no-cache',
        'X-Requested-With': 'XMLHttpRequest'
    }
})

httpClient.interceptors.request.use(
    (config) => {

        const area =
            router.currentRoute.fullPath.split('/')[1]

        if (area && area !== 'pdf')
            config.headers['X-Area'] = area

        return config
    },
    (error: AxiosError) => Promise.reject(error)
)

httpClient.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
        if (error.response?.status === 401) {
            const redirectUrl = error.response.headers['location']

            if (redirectUrl) {
                useStore().mutateProfile(null)
                window.location.href =
                    `${redirectUrl.split('?')[0]}?returnUrl=${router.currentRoute.fullPath}`

                return Promise.reject(null)
            }
        }

        return Promise.reject(error)
    }
)

type MyError = {
    type?: string
    title?: string
    status?: number
    traceId?: string
    errors?: {
        [key: string]: string[]
    }
}

export const withError = async <T>(task: Promise<T>, onError?: (e: AxiosError<MyError>) => void) => {
    try {
        return await task
    } catch (error) {

        if (!error)
            return

        const e = error as AxiosError<MyError>

        if (onError)
            onError(e)
        else {

            const errorDetails = {
                title: 'Error',
                message: 'There has been an error. Please try again.'
            }

            if (e.response?.status === 400) {

                let data: MyError

                if (
                    e.request.responseType === 'blob' &&
                    e.response.data instanceof Blob &&
                    e.response.data.type &&
                    e.response.data.type.toLowerCase().indexOf('json') !== -1
                ) {
                    data = JSON.parse(await e.response.data.text())
                } else {
                    data = e.response.data
                }

                if (data.title)
                    errorDetails.title = data.title

                const errors = data.errors
                if (errors) {
                    const errorMessage = errors[Object.keys(errors)[0]][0]
                    if (errorMessage)
                        errorDetails.message = errorMessage
                }
            } else if (e.response?.status === 418) {
                errorDetails.title = 'Maintenance mode'
                errorDetails.message = 'The site is undergoing an upgrade and is currently unavailable. We hope to resume normal service as soon as possible.'
            }
            else if (e.response?.status === 404) {
                errorDetails.title = 'Not found'
                errorDetails.message = 'We could not find this item.'
            }

            document.dispatchEvent(new CustomEvent('modal', { detail: errorDetails }))
        }
    }
}

export const getAsync = async <T>(path: string, config?: AxiosRequestConfig, onError?: (e: AxiosError) => void) =>
    await withError(httpClient.get<T>(path, config), onError)

export const postAsync = async <T>(path: string, data?: any, config?: AxiosRequestConfig, onError?: (e: AxiosError) => void) =>
    await withError(httpClient.post<T>(path, data, config), onError)

export const putAsync = async <T>(path: string, data?: any, config?: AxiosRequestConfig, onError?: (e: AxiosError) => void) =>
    await withError(httpClient.put<T>(path, data, config), onError)

export const deleteAsync = async <T>(path: string, config?: AxiosRequestConfig, onError?: (e: AxiosError) => void) =>
    await withError(httpClient.delete<T>(path, config), onError)

export const getApiAsync = async <T>(path: string, config?: AxiosRequestConfig, onError?: (e: AxiosError) => void) =>
    (await getAsync<IApiResponse<T>>(path, config, onError))?.data

export const getOptions = async <T extends IBaseEntity>(path: string) => {
    const response = await getAsync<T[]>(path)
    return mapToIOptions(response?.data ?? [])
}

const getUrlWithType = (baseUrl: string, type?: string) => {
    let url = `${baseUrl}`

    if (type)
        url += `?type=${type}`

    return url
}

const getEntitiesWithStartDate = async <T>(baseUrl: string, type?: string, startDate?: Date | string) => {
    let url = getUrlWithType(baseUrl, type)

    if (startDate)
        url += `&startDate=${formatDate(startDate, config.serverDateFormat)}`

    return (await getAsync<T[]>(url))?.data ?? []
}

const getEntitiesWithStartDateAndInsurer = async <T>(baseUrl: string, type?: string, insurerId?: number, startDate?: Date | string) => {
    let url = getUrlWithType(baseUrl, type)

    if (insurerId)
        url += `&insurerId=${insurerId}`

    if (startDate)
        url += `&startDate=${formatDate(startDate, config.serverDateFormat)}`

    return (await getAsync<T[]>(url))?.data ?? []
}

export const repository = {
    getBundles: async (type: EntityTypeType, hospitalListId?: number, startDate?: Date | string) => {
        let url = getUrlWithType(apiUrls.bundles, type)

        if (startDate)
            url += `&startDate=${formatDate(startDate, config.serverDateFormat)}`

        if (hospitalListId)
            url += `&hospitalListId=${hospitalListId}`

        return (await getAsync<IBundle[]>(url))?.data  ?? []
    },

    getModuleGroups: async (type?: EntityTypeType, startDate?: Date | string) =>
        await getEntitiesWithStartDate<IModuleGroup>(apiUrls.moduleGroups, type, startDate),

    getBusinessModuleGroups: async (startDate?: Date | string) =>
        await getEntitiesWithStartDate<IModuleGroup>(apiUrls.moduleGroups, 'Business', startDate),

    getPersonalModuleGroups: async (startDate?: Date | string) =>
        await getEntitiesWithStartDate<IModuleGroup>(apiUrls.moduleGroups, 'Personal', startDate),

    getModules: async (type: EntityTypeType, startDate?: Date | string) =>
        await getEntitiesWithStartDate<IModule>(apiUrls.modules, type, startDate),

    getUnderwritingTypes: async (type?: EntityTypeType) =>
        (await getAsync<IUnderwritingType[]>(getUrlWithType(apiUrls.underwritingTypes, type)))?.data ?? [],

    getBenefits: async (type?: BenefitTypeType) =>
        (await getAsync<IBenefit[]>(getUrlWithType(apiUrls.benefits, type)))?.data ?? [],

    getBenefitCategories: async (type?: BenefitTypeType) =>
        (await getAsync<IBenefitCategory[]>(getUrlWithType(apiUrls.benefitCategories, type)))?.data ?? [],

    getLeadTypes: async () =>
        (await getAsync<IBaseEntity[]>(apiUrls.leadTypes))?.data ?? [],

    getIndustryTypes: async () =>
        (await getAsync<IBaseEntity[]>(apiUrls.industryTypes))?.data ?? [],

    getImages: async (type?: ImageTypeType) =>
        (await getAsync<IImage[]>(getUrlWithType(apiUrls.images, type)))?.data ?? [],

    getUnderwritingQuestions: async (type: EntityTypeType, underwritingTypeId?: number, startDate?: Date | string) => {

        const searchParams = new URLSearchParams(window.location.search)

        searchParams.set('type', type)

        if (underwritingTypeId)
            searchParams.set('underwritingTypeId', `${underwritingTypeId}`)

        if (startDate)
            searchParams.set('startDate', formatDate(startDate, config.serverDateFormat)!)

        const url = `${apiUrls.underwritingQuestions}?${searchParams}`

        return (await getAsync<IUnderwritingQuestion[]>(url))?.data ?? []
    },

    getUnderwritingQuestionAnswers: async (underwritingQuestionId?: number) => {
        let url = apiUrls.underwritingQuestionAnswers

        if (underwritingQuestionId)
            url += `?underwritingQuestionId=${underwritingQuestionId}`

        return (await getAsync<IUnderwritingQuestionAnswer[]>(url))?.data ?? []
    },

    getMedicalConditionGroups: async (underwritingQuestionId?: number) => {
        let url = apiUrls.medicalConditionGroups

        if (underwritingQuestionId)
            url += `?underwritingQuestionId=${underwritingQuestionId}`

        return (await getAsync<IMedicalConditionGroup[]>(url))?.data ?? []
    },

    getHospitalLists: async (type: EntityTypeType, postcode?: string) => {
        let url = getUrlWithType(apiUrls.hospitalLists, type)

        if (postcode)
            url += `&postcode=${postcode}`

        return (await getAsync<IHospitalList[]>(url))?.data ?? []
    },

    getExcessTypes: async () =>
        (await getAsync<IExcessType[]>(apiUrls.excessTypes))?.data ?? [],

    getExcessAmounts: async () =>
        (await getAsync<IExcessAmount[]>(apiUrls.excessAmounts))?.data ?? [],

    getInsurers: async (type?: EntityTypeType, startDate?: Date) =>
        getEntitiesWithStartDate<IInsurer>(apiUrls.insurers, type, startDate),

    getInsurerSchemes: async (type: EntityTypeType, insurerId: number, startDate?: Date) =>
        await getEntitiesWithStartDateAndInsurer<IInsurerScheme>(apiUrls.insurerSchemes, type, insurerId, startDate),

    getInsurerExcessAmounts: async () =>
        (await getAsync<IBaseEntity[]>(apiUrls.insurerExcessAmounts))?.data ?? [],

    getInsurerModuleGroups: async (insurerId?: number) => {
        let url = apiUrls.insurerModuleGroups

        if (insurerId)
            url += `?insurerId=${insurerId}`

        return (await getAsync<IBaseEntity[]>(url))?.data ?? []
    },

    getInsurerModules: async (type: EntityTypeType, insurerId?: number, schemeId?: number, startDate?: Date | string, isPilot?: boolean) => {
        let url = getUrlWithType(apiUrls.insurerModules, type)

        if (startDate)
            url += `&startDate=${formatDate(startDate, config.serverDateFormat) }`

        if (insurerId)
            url += `&insurerId=${insurerId}`

        if (schemeId)
            url += `&schemeId=${schemeId}`

        if (isPilot !== undefined)
            url += `&isPilot=${isPilot}`

        return (await getAsync<IInsurerModule[]>(url))?.data ?? []
    },

    getInsurerHospitalLists: async (type: EntityTypeType, insurerId: number, postcode?: string, startDate?: Date | string) => {
        let url = getUrlWithType(apiUrls.insurerHospitalLists, type)

        url += `&insurerId=${insurerId}`

        if (postcode)
            url += `&postcode=${postcode}`

        if (startDate)
            url += `&startDate=${formatDate(startDate, config.serverDateFormat)}`

        return (await getAsync<IInsurerHospitalList[]>(url))?.data ?? []
    },

    getTemplates: async (name?: string, type?: TemplateTypeType, underwritingTypeId?: number) => {
        
        const searchParams = new URLSearchParams(window.location.search)

        if (name)
            searchParams.set('name', name)

        if (type)
            searchParams.set('type', type)

        if (underwritingTypeId)
            searchParams.set('underwritingTypeId', `${underwritingTypeId}`)

        const url = `${apiUrls.templates}?${searchParams}`

        return (await getAsync<ITemplate[]>(url))?.data ?? []
    },

    getPages: async () =>
        (await getAsync<IContentPage[]>(apiUrls.contentPages))?.data ?? [],

    getUnderwritingActions: async () =>
        (await getAsync<IUnderwritingAction[]>(apiUrls.underwritingActions))?.data ?? [],

    getTitles: async () =>
        (await getAsync<IBaseEntity[]>(apiUrls.titles))?.data ?? [],

    getGenders: async () =>
        (await getAsync<IBaseEntity[]>(apiUrls.genders))?.data ?? [],

    getPostcodeAreas: async () =>
        (await getAsync<IBaseEntity[]>(apiUrls.postcodeAreas))?.data ?? [],

    getQueryTypes: async (type: EntityTypeType) =>
        (await getAsync<IBaseEntity[]>(getUrlWithType(apiUrls.queryTypes, type)))?.data ?? [],

    getBusinessQuote: async (id: number) =>
        (await getAsync<IBusinessQuote>(`${apiUrls.businessQuotes}/${id}`))?.data,

    validateBusinessQuote: async (id: number, onError?: (e: AxiosError) => void) =>
        await putAsync<BusinessQuoteStatusType>(`${apiUrls.businessQuotes}/${id}/Validate`, onError)
}