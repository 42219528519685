import { isAfter, isEqual, parse, parseISO } from 'date-fns'
import type { RuleParamSchema } from 'vee-validate/dist/types/types'

const validate = (value: string, { min, inclusion = false, format }: Record<string, any>) => {

    const dateVal =
        format ?
            parse(value, format, new Date()) :
            parseISO(value)

    const minDate =
        typeof min === 'string' ?
            (format ?
                parse(min, format, new Date()) :
                parseISO(min)) :
            min

    if (!dateVal || !minDate)
        return false

    return isAfter(dateVal, minDate) || (inclusion && isEqual(dateVal, minDate))
}

const params: RuleParamSchema[] = [
    {
        name: 'min'
    },
    {
        name: 'inclusion'
    },
    {
        name: 'format'
    }
]

export { validate, params }

export default {
    validate,
    params
}