import "core-js/stable"
import "regenerator-runtime/runtime"

// doesn't work with class props
//import 'reflect-metadata'

import '~/class-component-hooks'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '21b46ffd-6af6-438b-89b9-1cbc8b26e26b',
        enableAutoRouteTracking: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        enableAjaxErrorStatusText: true,
        enableAjaxPerfTracking: true
    }
})

appInsights.loadAppInsights()
appInsights.trackPageView()

import Vue from 'vue'

import '~/plugins/bootstrap-vue'
import '~/plugins/vee-validate'

import '../node_modules/azure-maps-control/dist/atlas.min.css'

import { Plugin } from 'vue-fragment'
Vue.use(Plugin)

import App from '~/App'
import '~/registerServiceWorker'
import router from '~/router'
import store from '~/store'

Vue.config.productionTip = false

const app = new Vue({
    router,
    store,
    render: (h) => h(App)
})

app.$mount('#app')
