import Footer from '~/components/Footer'
import BaseComponent from '~/models/BaseComponent'
import { customerArea, helpPath, loggedOutPath, loginPath, questionnairePath, submittedPath, updatePasswordPath, verifyPath } from '~/router'
import { BCollapse, BDropdownItem, BIconPersonCircle, BImg, BNavbar, BNavbarBrand, BNavbarNav, BNavbarToggle, BNavItem, BNavItemDropdown } from 'bootstrap-vue'
import { Component } from 'vue-property-decorator'

@Component
export default class CustomerMain extends BaseComponent {

    get isLoginPage() {
        return (
            this.routePath.startsWith(`/${customerArea}/${loginPath}`) ||
            this.routePath.startsWith(`/${customerArea}/${verifyPath}`)
        )
    }

    get isVerify() {
        return this.routePath.indexOf(verifyPath) > 0
    }

    get isQuestionnaire() {
        return this.routePath.indexOf(questionnairePath) > 0
    }

    get isSubmittedPage() {
        return this.routePath.endsWith(submittedPath)
    }

    async created() {
        if (this.routePath.endsWith(customerArea))
            await this.$router.push({ path: `/${customerArea}/${loggedOutPath}` })
    }

    render() {
        return (
            <div class="d-flex flex-column h-100 w-100">
                {
                    !this.isLoginPage &&
                    <header>
                        <BNavbar toggleable="lg" type="dark" variant="primary">

                            <BNavbarBrand href="/">
                                <BImg alt="Vitality logo" height={50} src={require('~/assets/vitality-logo-white.svg')} />
                            </BNavbarBrand>

                            <BNavbarToggle target="nav-collapse" />

                            <BCollapse id="nav-collapse" is-nav>
                                <BNavbarNav class="ml-auto">
                                    {
                                        this.isQuestionnaire && !this.isSubmittedPage &&
                                        [
                                            <BNavItem to={`/${customerArea}/${helpPath}`}>How to complete this form</BNavItem>,
                                            <BNavItem to={`/${customerArea}/${updatePasswordPath}`}>Update your password</BNavItem>
                                        ]
                                    }

                                    {
                                        (this.isQuestionnaire || this.isVerify) &&
                                        <BNavItemDropdown right>
                                            <template slot="button-content">
                                                <BIconPersonCircle />
                                            </template>

                                            <BDropdownItem on-click={this.logOut}>Sign out</BDropdownItem>

                                        </BNavItemDropdown>
                                    }
                                </BNavbarNav>
                            </BCollapse>

                        </BNavbar>
                    </header>
                }

                <main role="main" class="flex-shrink-0">
                    <transition name="bounce-fade" mode="out-in">
                        <router-view />
                    </transition>
                </main>

                <Footer />
            </div>
        )
    }
}