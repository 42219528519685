const validate = (value: string) => {

    const re = /^\d{2}-\d{2}-\d{2}$/

    return re.test(value)
}

export { validate }

export default {
    validate
}