import { useArea } from '~/composables'
import { pdfArea } from '~/router'
import { defineComponent } from 'vue'
import { addScripts } from '~/utils'

export default defineComponent({
    setup() {
        const area = useArea()

        if (area.isPdfPage.value)
            document.documentElement.classList.add(pdfArea)
        else
            addScripts()

        return {}
    },
    render() {
        return <router-view />
    }
})