const validate = (value: string) => {

    const re = /^[a-zA-ZÀ-ž \-']*$/

    return re.test(value)
}

export { validate }

export default {
    validate
}