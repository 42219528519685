import { pdfArea } from "~/router"
import { isRoute } from "~/utils"
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'

export const useArea = () => {

    const route = useRoute()

    const isArea = (area: string) =>
        isRoute(route, `/${area}`)

    const isPdfPage = computed(() => isArea(pdfArea))

    return {
        isPdfPage
    }
}

export const useApi = () => {

    const route = useRoute()

    const path = computed(() => route.meta?.apiUrl as string | undefined ?? '')

    const entityId = computed(() => route.params['id'])

    const entityPath = computed(() => `${path.value}/${entityId.value}`)

    return {
        path,
        entityId,
        entityPath
    }
}