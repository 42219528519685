import { defineComponent } from 'vue'
import { BSpinner } from 'bootstrap-vue'

export default defineComponent({
    inheritAttrs: false,
    setup() {
        return () =>
            <div class="d-flex justify-content-center my-5 py-5" key="spinner">
                <BSpinner variant="primary" label="Loading..." />
            </div>
    }
})