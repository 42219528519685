import type { IGridState, ITableFilter, Nullable } from '@/models'
import { Mutation, State } from 'vuex-simple'

export class GridModule implements IGridState {

    @State() filters: ITableFilter[] = []
    @State() search: Nullable<string> = null
    @State() fieldKey: Nullable<string> = null
    @State() sortBy: Nullable<string> = null
    @State() sortDesc: Nullable<boolean> = null
    @State() currentPage = 1
    @State() totalRows = 0
    @State() perPage = 10

    @Mutation() mutateFilters(delta: ITableFilter[]) {
        this.filters = [...delta]
    }

    @Mutation() mutateField(delta: Nullable<string>) {
        this.fieldKey = delta
    }

    @Mutation() mutateSearch(delta: Nullable<string>) {
        this.search = delta
    }

    @Mutation() mutateSortBy(delta: Nullable<string>) {
        this.sortBy = delta
    }

    @Mutation() mutateSortDesc(delta: Nullable<boolean>) {
        this.sortDesc = delta
    }

    @Mutation() mutateCurrentPage(delta: number) {
        this.currentPage = delta
    }

    @Mutation() mutateTotalRows(delta: number) {
        this.totalRows = delta
    }

    @Mutation() mutatePerPage(delta: number) {
        this.perPage = delta
    }
}
