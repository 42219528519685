const validate = (value: string) => {

    const re = /^\+\d{1,3}-0\d{9,10}$/

    return re.test(value)
}

export { validate }

export default {
    validate
}