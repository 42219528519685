import { isBefore, isEqual, parse, parseISO } from 'date-fns'
import type { RuleParamSchema } from 'vee-validate/dist/types/types'

const validate = (value: string, { max, inclusion = false, format }: Record<string, any>) => {

    const dateVal =
        format ?
            parse(value, format, new Date()) :
            parseISO(value)

    const maxDate =
        typeof max === 'string' ?
            (format ?
                parse(max, format, new Date()) :
                parseISO(max)) :
            max

    if (!dateVal || !maxDate)
        return false

    return isBefore(dateVal, maxDate) || (inclusion && isEqual(dateVal, maxDate))
}

const params: RuleParamSchema[] = [
    {
        name: 'max'
    },
    {
        name: 'inclusion'
    },
    {
        name: 'format'
    }
]

export { validate, params }

export default {
    validate,
    params
}