const validate = (value: string) => {

    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,63}@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/

    return re.test(value)
}

export { validate }

export default {
    validate
}