import type { RuleParamSchema } from 'vee-validate/dist/types/types'

const validate = (value: string | ArrayLike<unknown>, { min, max }: Record<string, any>) => {
    const minLength = typeof min === 'string' ? parseInt(min) : min
    const maxLength = typeof max === 'string' ? parseInt(max) : max

    return value.length >= minLength && value.length <= maxLength
}

const params: RuleParamSchema[] = [
    {
        name: 'min'
    },
    {
        name: 'max'
    }
]

export { validate, params }

export default {
    validate,
    params
}