import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ExpandTransition extends Vue {
    beforeEnter() {
        //const el = element as HTMLElement

        //el.style.overflow = `auto`

        // might be needed
        //el.style.willChange = `height`
        //el.style.transform = `translateZ(0)`
        //el.style.backfaceVisibility = `hidden`
        //el.style.perspective = `1000px`
    }

    enter(element: Element) {
        const el = element as HTMLElement

        const { width } = getComputedStyle(el)

        el.style.width = width
        //el.style.overflow = `auto`
        el.style.position = `absolute`
        el.style.visibility = `hidden`
        el.style.height = `auto`
        
        const { height } = getComputedStyle(el)

        el.style.width = `auto`
        //el.style.overflow = `hidden`
        el.style.position = `static`
        el.style.visibility = `visible`
        el.style.height = `0`

        // eslint-disable-next-line no-unused-expressions
        getComputedStyle(el).height

        requestAnimationFrame(() => {
            el.style.height = height
        })
    }

    afterEnter(element: Element) {
        const el = element as HTMLElement

        el.style.height = `auto`
        //el.style.overflow = `auto`
    }

    onAfterAppear(element: Element) {
        const el = element as HTMLElement

        el.style.height = `auto`
        //el.style.overflow = `auto`
    }

    leave(element: Element) {
        const el = element as HTMLElement

        const { height } = getComputedStyle(el)

        // eslint-disable-next-line no-param-reassign
        el.style.height = height

        // eslint-disable-next-line no-unused-expressions
        getComputedStyle(el).height

        requestAnimationFrame(() => {
            el.style.height = `0`
        })
    }

    render() {
        return (
            <transition name="expand" onBeforeEnter={this.beforeEnter} onEnter={this.enter} onAfterEnter={this.afterEnter} onLeave={this.leave}>
                { this.$slots.default }
            </transition>
        )
    }
}

